:root {
    font-family: sans-serif;
    color: var(--color-white);
    background-color: var(--color-dark-grey);

    --color-black: #141414;
    --color-dark-grey: #242424;
    --color-dark-blue:#28262d;
    --color-grey: #3a3a3a;
    --color-white: #ebebeb;
    --color-red: #c51d25;
    --color-neon-red: #ff6080;
    --color-neon-green: #30e530;
    --color-green: #18ad63;
    --color-neon-blue: #10d0ff;
    --color-blue: #236bc9;
    --color-yellow: #f8e914;
    --color-light-grey: #b8b8b8;

}

* {
    box-sizing: border-box;
    margin: 0%;
    padding: 0%;
}

a {
    color: var(--color-neon-blue);
    transition-duration: 150ms;
}
a:hover {
    color: var(--color-yellow);
}

.container {
    box-sizing: border-box;
    margin: 0%;
    padding: 1%;
    width: 100%;
}

span {
    color: var(--color-neon-red);
}
/*Nav section ----------------------------------------------*/
nav {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    align-content: center;
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: var(--color-black);
    width: 100%;
}

nav a{
    color: inherit;
    text-decoration:none;
}
nav a:hover{
    color: inherit;
}
nav a:visited{
    color: inherit;
}

nav ul {
    list-style-type:none;
    display: flex;
    flex-direction: row;
}

.navBar-link {
    padding: 10px;
    font-weight: bold;
    text-transform: capitalize;
    color: var(--color-white);
    transition: all;
    transition-duration: 175ms;
}


.navBar-link:hover {
    color: var(--color-neon-red);
}

/*Spotlight section ----------------------------------------------*/
.sketchfab {
    display: flex;
    width: 35vw;
    height: 100%;
    margin-top: 15px;
    margin-right: 20px;
}

.section {
    width: 100%;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1%;
    padding-bottom: 2%;
    padding-right: 15%;
    padding-left: 15%;
    border-width: 2px;
    border-left-width: 0;
    border-right-width: 0;
}

.panel {
    width: 100%;
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1%;
    padding-bottom: 3%;
    padding-right: 15%;
    padding-left: 15%;
    border: solid;
    border-width: 0;
    border-top-width: 3px;
    background-color: var(--color-grey);
    box-shadow: 0px 3px 12px var(--color-black);
}

.panel * {
    padding-bottom: 1%;
}

.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.horizontal-scroll {
    contain: content;
    display: flex;
    flex-direction: row;
    justify-self: center;
    overflow-x:scroll;
    overflow-y: hidden;
    width: 100vw;
}

.horizontal-scroll::-webkit-scrollbar{
    background-color: transparent;
    height: 14px;
}
.horizontal-scroll::-webkit-scrollbar-track-piece{
    background-color: var(--color-dark-grey);
}
.horizontal-scroll::-webkit-scrollbar-corner{
    background-color:var(--color-dark-grey);
}
.horizontal-scroll::-webkit-scrollbar-thumb{
    background-color: var(--color-light-grey);
    height: 3vh;
}

.vertical {
    display: flex;
    flex-direction: column;
}

.spotlight-item {
    contain: content;
    will-change: contents;
    display: flex;
    overflow: hidden;
    align-items: center;
    width: 100%;
    height: 55vh;
    box-shadow: 3px 3px 12px var(--color-black);
    margin-bottom: 3%;
}

.spotlight-item img, video {
    will-change: transform;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    animation: forwards;
    animation: zoom-slow 5000ms;
}

.image-text{
    position: absolute;
    color: var(--color-white);
    left: 5%;
    bottom: 2%;
    text-shadow: 2px 2px 3px var(--color-black);
    animation: slide-fade-in 1000ms;
}

.image-text h3{
    font-size: xx-large;
    color: var(--color-neon-red);
}

/*Cards section ----------------------------------------------*/
.skillcard-container{
    contain: content;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 3%;
}

.thumbnail {
    object-fit:cover;
    overflow:hidden;
    align-self: flex-start;
    height: 25vh;
    width: 25vh;
    margin-right: 5%;
    transition-duration: 200ms;
}
.thumbnail-wide {
    object-fit:cover;
    overflow:hidden;
    align-self: flex-start;
    height: 25vh;
    width: 50vh;
    margin-right: 5%;
    transition-duration: 200ms;
}

.thumbnail:hover {
    z-index: 50;
    transform: scale(105%);
    cursor:pointer;
}
.picture-link {
    margin-right: 5%;
}

.media-display {
    height: 95vh;
    width: 95vw;
}

.card {
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    padding: 25px;
    padding-top: 10px;
    background-color: var(--color-dark-blue);
    border: solid;
    border-width: 0px;
    border-top-width: 3px;
    box-shadow: 3px 3px 12px var(--color-black);
    margin: 1%;
    margin-top: 1vh;
    object-fit: scale-down;
    width: 100%;
    min-width: 28%;
    border-radius: 5px;
    transition-duration: 300ms;
}
.card div {
    margin-right: 5%;
    width: 100%;
}

.card h2{
    margin-bottom: 5vh;
}

.card li {
    color: var(--color-light-grey);
    margin-bottom: 15px;
    list-style: inside;
    list-style-type: circle;
    transition-duration: 250ms;
    font-size:large;
}

.card li:hover{
    color: var(--color-white);
}


/*About section ----------------------------------------------*/
.about-section{
    white-space: pre-wrap;
    display: flex;
    flex-direction: column;
    padding: 1%;
    padding-bottom: 2%;
    padding-right: 15%;
    padding-left: 15%;
    border-width: 2px;
    border-left-width: 0;
    border-right-width: 0;
    background-color: var(--color-grey);
    box-shadow: 0px 3px 12px var(--color-black);
}

.about-section p{
    margin: 1%;
}
/*Email section ----------------------------------------------*/
.email-section{
    background-color: var(--color-grey);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: solid;
    border-color: var(--color-yellow);
    border-width: 0;
    border-top-width: 3px;
    padding: 5%;
    padding-top: 1%;
    box-shadow: 0px 3px 12px var(--color-black);
}

.email-section label{
    margin-top: 1%;
    margin-bottom: 1%;
}


/*Footer section ----------------------------------------------*/
.footer-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 5%;
}

.social {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.social a{
    color: var(--color-white);
    transition-duration: 250ms;
    font-size:225%;
    padding: 3px;
    padding-left: 6px;
    padding-right: 6px;
    margin: 3%;
}

.social a:hover{
    color: inherit;
    background-color: var(--color-neon-red);
    border-radius: 35px;

}

.muted-text {
    color: var(--color-light-grey);
}

/*Animations section ----------------------------------------------*/

@keyframes zoom-slow {
    from{
        transform: scale(115%);

    }
    to{
        transform: scale(100%);
    }
    
}

@keyframes slide-fade-in {
    from{
        opacity: 0;
        transform: translateY(-20%);
    }
    to{
        opacity: 100%;
        transform: translateY(0%);
    }
}

@keyframes rainbow {
    from{
        color: hsl(0, 75%, 60%);
    }
    17%{
        color: hsl(59, 75%, 50%);
    }
    34%{
        color: hsl(118, 75%, 50%);
    }
    51%{
        color: hsl(170, 75%, 50%);
    }
    68%{
        color: hsl(234, 75%, 60%);
    }
    85%{
        color: hsl(295, 75%, 50%);
    }
    100%{
        color: hsl(357, 75%, 60%);
    }
}
